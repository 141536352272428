import type { TopSlideBanner } from "~/types";
import {
  plan,
  set_discounts,
  contest,
  mobi,
  campaign,
  mizu,
} from "~/constants/top/slideBanner";
import { prefToAlphabet } from "~/utils/sekou/area";

export default async function () {
  // 地域サイト用のスライドを生成
  const local_site = useLocalSite();
  const pref_key = prefToAlphabet(local_site.prefecture);

  // 件数取得
  const { data: total } = await useTotalCount();

  const local_sekou: TopSlideBanner = {
    alt: "関西でNo1外構施工例実績。外構・リフォーム工事施工例を毎日更新中",
    text: {
      type: "sekou",
      value: addComma(total.sekou),
      position: { x: 20, y: 74 },
    },
    path: "/images/banner/sekou.png",
    link: `/sekou/${pref_key}`,
    preload: true,
  };

  const review: TopSlideBanner = {
    alt: "外構・リフォーム工事をしたお客様の声を公開しています！",
    text: {
      type: "review",
      value: addComma(total.review),
      position: { x: 32, y: 74 },
    },
    path: "/images/banner/review.png",
    link: "/e_list",
  };

  // 共通分とともに並べて配列に
  const slides: TopSlideBanner[] = [
    local_sekou,
    plan,
    set_discounts,
    contest,
    review,
    mobi,
    campaign,
    mizu,
  ];

  return slides;
}
